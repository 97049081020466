/* eslint-disable no-undef, max-len, no-invalid-this */
const LocationMap_CreateMarkers = {
  init(map, markersList) {
    let theMap = map;
    let markers = markersList;
    let bounds = new google.maps.LatLngBounds();
    let locations = $("#location-map").data("locations");
    let infoWindow = new google.maps.InfoWindow();

    for (let i = 0; i < locations.length; i += 1) {
      let location = locations[i];
      LocationMap_CreateMarkers.createMarker(location, theMap, markers, infoWindow);
      bounds.extend(markers[i].latLng);
    }
    map.fitBounds(bounds);
  },

  createMarker(location, theMap, markers, infoWindow) {
    let markerInfo = LocationMap_CreateMarkers.buildLocationMarkerInfo(location);
    let latLng = new google.maps.LatLng(location.lat, location.lng);
    let alert = LocationMap_CreateMarkers.buildLocationAlert(location);
    let marker = new google.maps.Marker({
      position: latLng,
      icon: markerInfo.icon,
      map: theMap
    });

    google.maps.event.addListener(marker, "click", function() {
      if (infoWindow) {
        infoWindow.close();
      }
      infoWindow.setContent(LocationMap_CreateMarkers.buildLocationContent(location, markerInfo.addressText, markerInfo.branchType));
      infoWindow.open(theMap, this);

      $(".location-map__listing").removeClass("location-map__listing--active");
    });

    markers.push({
      marker: marker,
      branchType: location.type,
      name: location.name,
      icon: markerInfo.icon,
      address: markerInfo.addressText,
      phone: location.phone,
      hours: location.hours,
      latLng: latLng,
      lat: location.lat,
      lng: location.lng,
      url: location.url,
      alert: alert,
      is_hub: location.is_hub,
      distance: location.distance
    });
  },

  buildLocationAlert(location) {
    if (location.alert) {
      return "<i class='fa fa-exclamation-circle text-warning mr-1'></i><span class='small'>" +
      location.alert + "</span>";
    }
    return "";
  },

  buildLocationMarkerInfo(location) {
    if (location.type == "branch") {
      return {
        addressText: location.address,
        branchType: "Branch",
        icon: {
          url: $("#location-map").data("assets").branch_marker_url
        }
      };
    }

    let address = location.is_private ? "" : location.address;

    return {
      addressText: address,
      branchType: "Drop Spot",
      icon: {
        url: $("#location-map").data("assets").drop_spot_marker_url
      }
    };
  },

  buildLocationContent(location, addressText, branchType) {
    return "<div>" +
    "<div class='location-map__pop-title'>" + branchType + ": " + location.name + "</div>" +
    "<div class='location-map__pop-address'>" + addressText + "</div>" +
    "<a data-turbolinks='false' class='location-map__pop-link' href='" + location.url + "'>See more details</a>" +
    "</div>";
  }
};

export { LocationMap_CreateMarkers };
