/* eslint-disable no-undef */
import { ScrollToElement } from "../scroll_to_element";

const LocationMap_CreateList = {
  init(theMarkers) {
    let markers = theMarkers;
    let $branchList = $(".js-location-map__branch-list");
    let $dropspotList = $(".js-location-map__dropspot-list");
    let dropspotCount = 0;
    let branchCount = 0;
    for (let i = 0; i < markers.length; i += 1) {
      let currentMarker = markers[i];
      if (currentMarker.marker.map) {
        if (currentMarker.branchType === "branch") {
          LocationMap_CreateList.addToList($branchList, currentMarker, i);
          branchCount += 1;
        } else if (currentMarker.branchType === "drop_spot_chit_chats" ||
            currentMarker.branchType === "drop_spot_partner") {
          LocationMap_CreateList.addToList($dropspotList, currentMarker, i);
          dropspotCount += 1;
        }
      }
    }

    LocationMap_CreateList.addDropspotCount(dropspotCount);
    LocationMap_CreateList.addBranchCount(branchCount);
  },

  addToList($list, marker, index) {
    let local_lang = $("html").attr("lang");
    let listing = "<div id='marker-" + index +
    "' class='location-map__listing location-map__listing--" + marker.branchType + "'>" +
    LocationMap_CreateList.buildBranchTitle(marker, local_lang) +
    LocationMap_CreateList.buildDistance(marker, local_lang) +
    "<div class='location-map__branch-snippet'>" +
    LocationMap_CreateList.buildAddress(marker.address, local_lang) +
    LocationMap_CreateList.buildPhone(marker.phone, local_lang) +
    LocationMap_CreateList.buildHoursTable(marker.hours, local_lang) +
    LocationMap_CreateList.buildLocationLink(marker.url, local_lang) +
    "</div></div>";
    $list.append(listing);

    // Bind click event to listing
    $("#marker-" + index).on("click", function() {
      google.maps.event.trigger(marker.marker, "click");
    });

    // Only scroll to list item on desktop
    LocationMap_CreateList.scrollToMarker(marker, index);
  },

  scrollToMarker(marker, index) {
    google.maps.event.addListener(marker.marker, "click", function() {
      if (marker.branchType === "drop_spot_chit_chats") {
        $("#drop-spot-tab").trigger("click");
      } else if (marker.branchType === "branch") {
        $("#branch-tab").trigger("click");
      }

      let selectedLocation = $("#marker-" + index);
      const mediaQuery = window.matchMedia("(min-width: 992px)");
      mediaQuery.addListener(LocationMap_CreateList.handleWidthChange);
      LocationMap_CreateList.handleWidthChange(mediaQuery, selectedLocation);
    });
  },

  handleWidthChange(e, selectedLocation) {
    if (e.matches) {
      ScrollToElement.scroll(selectedLocation);
    }
  },

  buildBranchTitle(marker, lang) {
    let location_url = String(marker.url).replace("en", lang);

    return "<div class='location-map__header'>" +
    "<h3 class='location-map__listing__title'>" +
    "<img src='" + marker.icon.url + "' alt=''>" +
    "<a class='location-map__listing__link' data-turbolinks='false' href='" + location_url + "'>" +
    marker.name + "</a>" +
    LocationMap_CreateList.buildHubStatus(marker, lang) + "</h3>" +
    "<div class='location-map__alert'>" + marker.alert + "</div>" +
    "</div>";
  },

  buildDistance(marker) {
    if (marker.distance) {
      return "<div class='mb-2'><strong>Distance:</strong> " +
      marker.distance.toFixed(2) +
      "km" +
      "</div>";
    }
    return "";
  },

  buildHubStatus(marker, lang) {
    let hubText = lang === "en" ? "Hub" : "Centrale";
    if (marker.is_hub) {
      return "<span class='h5 font-weight-normal font-italic pl-1'>(" + hubText + ")</span>";
    }
    return "";
  },

  /* eslint-disable complexity */
  buildHoursTable(hours, local_lang) {
    let dayLabel = local_lang === "en" ? "Day" : "Jour";
    let hourLabel = local_lang === "en" ? "Hours" : "Heures";

    return "<div class='location-map__hours table-responsive'>" +
    "<h4 class='h5'>" + hourLabel + "</h4>" +
    "<table class='table table-sm table--borderless'>" +
    "<thead><th class='p-0'><span class='sr-only'>" + dayLabel + "</span></th>" +
    "<th class='p-0'><span class='sr-only'>" + hourLabel + "</span></th></thead>" +
    "<tbody><tr>" +
    "<td class='table__td--shrink pl-0 pb-0'>" + hours.zero.day + "</td>" +
    "<td class='pb-0'>" + hours.zero.schedule + "</td>" +
    "</tr><tr>" +
    "<td class='table__td--shrink pl-0 pb-0'>" + hours.one.day + "</td>" +
    "<td class='pb-0'>" + hours.one.schedule + "</td>" +
    "</tr><tr>" +
    "<td class='table__td--shrink pl-0 pb-0'>" + hours.two.day + "</td>" +
    "<td class='pb-0'>" + hours.two.schedule + "</td>" +
    "</tr><tr>" +
    "<td class='table__td--shrink pl-0 pb-0'>" + hours.three.day + "</td>" +
    "<td class='pb-0'>" + hours.three.schedule + "</td>" +
    "</tr><tr>" +
    "<td class='table__td--shrink pl-0 pb-0'>" + hours.four.day + "</td>" +
    "<td class='pb-0'>" + hours.four.schedule + "</td>" +
    "</tr><tr>" +
    "<td class='table__td--shrink pl-0 pb-0'>" + hours.five.day + "</td>" +
    "<td class='pb-0'>" + hours.five.schedule + "</td>" +
    "</tr><tr>" +
    "<td class='table__td--shrink pl-0 pb-0'>" + hours.six.day + "</td>" +
    "<td class='pb-0'>" + hours.six.schedule + "</td>" +
    "</tr><tr></tbody></table></div>";
  },

  buildPhone(phone, lang) {
    let phoneLabel = lang === "en" ? "Phone" : "Téléphone";

    if (phone !== null) {
      return "<div class='location-map__phone'><h4 class='h5'>" + phoneLabel + "</h4>" + phone + "</div>";
    }
    return "";
  },

  buildAddress(address, lang) {
    let addressLabel = lang === "en" ? "Address" : "Adresse";

    if (address !== "") {
      return "<div class='location-map__address'><h4 class='h5'>" + addressLabel + "</h3>" + address + "</div>";
    }
    return "";
  },

  buildLocationLink(url, lang) {
    let location_url = String(url).replace("en", lang);
    let linkText = lang === "en" ? "View details and directions" : "Voir les détails et itinéraires";
    return "<a class='location-map__read-more small' data-turbolinks='false' href='" + location_url + "'>" +
    linkText + "</a>";
  },

  addDropspotCount(count) {
    if (count == 0) {
      $(".js-location-map__empty-map--drop-spot").show();
    } else {
      $(".js-location-map__dropspot-count").text("(" + count + ")");
      $(".js-location-map__empty-map--drop-spot").hide();
    }
  },

  addBranchCount(count) {
    if (count == 0) {
      $(".js-location-map__empty-map--branch").show();
    } else {
      $(".js-location-map__branch-count").text("(" + count + ")");
      $(".js-location-map__empty-map--branch").hide();
    }
  }
};

export { LocationMap_CreateList };
